import { template as template_de3176fd991a47b1befc13f6c2f38f31 } from "@ember/template-compiler";
const FKText = template_de3176fd991a47b1befc13f6c2f38f31(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
